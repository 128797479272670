:root {
  --backgroundColor1: #37C1CE;
  --appbgColour: rgba(0, 0, 0, 0.97);
  --navbarBlack: black;
  --btnNavbar: rgba(255, 255, 255, .1);
}

/*Mobile Portrait*/
@media only screen and (min-device-width:320px) and (max-device-width:767px) and (orientation: portrait) {
  .message-div-parent {
    background-color: var(--backgroundColor1);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message-sent-container {
    text-align: center;
    background-color: black;
    color: var(--backgroundColor1);
    padding: 2em;
    border-radius: 10px;
    font-size: 1.5em;
    -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  @-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }

  .unailogo {
    display: none;
  }

  .message-sent {
    color: var(--backgroundColor1)
  }

  .button-back {
    border: none;
    border-radius: 3px;
    background-color: var(--backgroundColor1);
    color: white;
    padding: .5em;
    margin-bottom: 1em;
  }
}

@media only screen and (min-device-width:320px) and (max-device-width:767px) and (orientation: landscape) {
  .message-div-parent {
    background: rgb(1, 144, 245);
    background: linear-gradient(135deg, rgba(1, 144, 245, 0.4321078773306197) 0%, rgba(1, 144, 245, 1) 65%);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message-sent-container {
    text-align: center;
    background-color: rgb(250, 169, 29);
    color: white;
    padding: 2em;
    border-radius: 10px;
    font-size: 1.5em;
    -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  @-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }

  .unailogo {
    display: none;
  }
}

/*tablet portrait*/
@media only screen and (min-width:768px) and (max-width:1024px) and (orientation:portrait) {
  .message-div-parent {
    background: rgb(1, 144, 245);
    background: linear-gradient(135deg, rgba(1, 144, 245, 0.4321078773306197) 0%, rgba(1, 144, 245, 1) 65%);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message-sent-container {
    text-align: center;
    background-color: rgb(250, 169, 29);
    color: white;
    padding: 2em;
    border-radius: 10px;
    font-size: 1.5em;
    -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  @-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }

}

/* Regular Tablet landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .message-div-parent {
    background-color: var(--backgroundColor1);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message-sent-container {
    text-align: center;
    background-color: black;
    color: var(--backgroundColor1);
    padding: 2em;
    border-radius: 10px;
    font-size: 1.5em;
    -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  @-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }

  .unailogo {
    display: none;
  }

  .message-sent {
    color: var(--backgroundColor1)
  }

  .button-back {
    border: none;
    border-radius: 3px;
    background-color: var(--backgroundColor1);
    color: white;
    padding: .5em;
    margin-bottom: 1em;
  }

}

@media (min-width:1024px) {
  .message-div-parent {
    background-color: var(--backgroundColor1);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message-sent-container {
    text-align: center;
    background-color: black;
    color: var(--backgroundColor1);
    padding: 2em;
    border-radius: 10px;
    font-size: 1.5em;
    -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  @-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }

  .unailogo {
    display: none;
  }

  .message-sent {
    color: var(--backgroundColor1)
  }

  .button-back {
    border: none;
    border-radius: 3px;
    background-color: var(--backgroundColor1);
    color: white;
    padding: .5em;
    margin-bottom: 1em;
  }


}