:root {
  --backgroundColor1: #37C1CE;
  --personalInfoColor: #126b73;
  --appbgColour: rgba(0, 0, 0, 0.97);
  --navbarBlack: black;
  --btnNavbar: rgba(255, 255, 255, .1);
}

* {
  font-family: 'Epilogue', sans-serif;
}

html {
  scroll-behavior: smooth;
}

[type=button]:not(:disabled) {
  outline: none
}

button:focus {
  outline: none
}

.fa-plus-circle {
  vertical-align: middle;
  padding-right: .5em;
}


.nav-link {
  color: var(--backgroundColor1);
}

.nav-link:hover {
  color: white;
}

.work--icons {
  color: white
}

.work-exp-linkedin {
  color: #0A66C2;
}

.udemy {
  height: 1.2rem;

}


/*animations*/
.swing-in-top-fwd {
  -webkit-animation: swing-in-top-fwd 0.8s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
  animation: swing-in-top-fwd 0.8s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}


/*Mobile Portrait*/
@media only screen and (min-device-width:320px) and (max-device-width:767px) and (orientation: portrait) {


  main {
    width: 100vw;
    background-color: var(--backgroundColor1);
    overflow-x: hidden
  }

  .navbar {

    border-radius: 0 0 5px;
    background: var(--appbgColour);
    line-height: 2.5em;
    margin-bottom: 2em;
  }

  .navbar-nav {
    height: 38vh;
    width: 90vw;
    border-radius: 10px;
    margin-left: 1em !important;
  }

  .navbar-nav>li {
    margin-left: 1em;
  }

  .navbar-toggler-icon {
    color: var(--backgroundColor1);
    font-weight: 900;
    font-family: 'Font Awesome\ 5 Free';
    vertical-align: middle;
    font-size: 1.5em;
    align-self: center;
  }

  .navbar-toggler {
    background-color: white;
    margin-top: .5em;
    height: 2em;
  }

  .unai-pic {
    height: 12em;
    display: block;
    margin: auto;
    border-radius: 50%;
    position: relative;
  }

  .estudios {
    display: none;
  }

  .about-div,
  .latestWorks-div {
    background-color: white;
  }


  .h2--about,
  .h2-About {
    background: var(--appbgColour);
    background: -webkit-linear-gradient(to top, var(--appbgColour) 30%, var(--backgroundColor1) 100%);
    background: -moz-linear-gradient(to top, var(--appbgColour) 30%, var(--backgroundColor1) 100%);
    background: linear-gradient(to top, var(--appbgColour) 30%, var(--backgroundColor1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 1.6em;
    padding-top: 1em;
  }

  .about-hr {
    display: none;
  }

  .about-info--1,
  .education-info,
  .about-info,
  .info-wrapper,
  .project-info,
  .cert-wrapper,
  .work-div,
  .education-div,
  .latestWorks-div,
  .contact-div {
    font-size: 18px;
    padding: 0 1em 1em 1em;
    line-height: 1.5em;
  }

  .about-info--1>p {
    color: var(--personalInfoColor);
    line-height: 2em;
  }

  .portfolio-div {
    background-color: var(--appbgColour);
  }

  .portfolio-div>.h2-About {
    color: white !important;
    -webkit-text-fill-color: white;
  }

  .latest-works-p {
    color: var(--personalInfoColor);

  }

  .project-info>p,
  .cert-wrapper>ul,
  .p-info {
    background-color: white;
    color: var(--personalInfoColor);
    border-radius: 10px;
    padding: 1em;
    margin-bottom: 1em;
    font-weight: 500;
    box-shadow: 1px 3px 1px var(--appbgColour);
  }

  .about-info--1>p:last-child,
  .education-info>p:last-child,
  .about-info>p:last-child,
  .info-wrapper>p:last-child,
  .project-info>p:last-child,
  .cert-wrapper>p:last-child {
    margin-bottom: 1em !important;
  }

  .p-info>.text-light {
    color: var(--personalInfoColor) !important;
    font-size: 1em;
    float: none !important
  }

  .webDesigner-p {
    display: none;
  }

  .logo--tech {
    height: 3em;
    object-fit: contain;
    padding: 1px 15px;
  }

  .heroku,
  .firebase {
    margin-left: .8em;
  }

  .p-description {
    color: white;
    font-size: 1.3em;
    text-align: center;
    font-weight: 700;
  }

  .educ-pic,
  .cert-pic {
    display: none;
  }

  .hr-desktop {
    display: none;
  }

  .softSkills-list {
    width: 78%;
    text-align: center;
    margin: auto;
    line-height: 9.3em;
    margin-top: 0em;
  }

  .portfolio--techs {
    min-height: 30px;
    max-height: 40px;
    margin-bottom: 1em;
    filter: opacity(1) drop-shadow(0 0 0 white);
  }

  .udemy--list {
    list-style: none;
    margin-bottom: 2em;
  }

  .logo-works-desktop {
    height: 150px;
  }

  .h3-heading-enquieries {
    color: var(--backgroundColor1);
    font-weight: 700;
    text-align: left;
    margin-top: 2em;
    margin-left: 1em;
    padding-top: 1em
  }

  .about--mobile,
  .workexp--mobile,
  .portfolio--mobile {
    background-color: white;
    padding: 1em 1.5em 1em 1.5em;
    margin-top: .5em;

  }

  .about--mobile p,
  .workexp--mobile p,
  .portfolio--mobile p {
    color: black;
  }

  .date-works {


    color: white;
    font-weight: 900;
    vertical-align: middle;
    font-size: 1.1em;
  }

  .logo-works {
    height: 150px;
  }

  .p-workplace {
    color: var(--backgroundColor1);
  }

  .work-icons--div {
    display: flex;
    justify-content: center;
  }

  .a-project {
    color: var(--backgroundColor1);
    font-weight: 800;
  }

  .form-fields {
    border: 1px solid var(--backgroundColor1);
  }

  .form-fields::placeholder {
    color: var(--backgroundColor1);
    font-weight: 600;
  }

  .footer {
    background: var(--appbgColour)
  }

  .div-contact {
    display: flex;
    flex-direction: column;
    padding-bottom: 1.2em;
    line-height: 1.7em;
  }

  .p-contactinfo {
    color: white;
    width: 30em;
    margin-left: 2em;
  }

  .p-contactinfo-firm {
    color: white;
    margin-left: 1.8em
  }

  .btn-send {
    background: var(--backgroundColor1);
    color: white;
    font-weight: 700;
    border: none;
    padding: .5em;
    margin-bottom: 1em;
    border-radius: 5px;

  }

  .pic-landscape {
    visibility: hidden !important;
    z-index: -1000;
    position: absolute;
    height: 0;
  }

  .a-cv {
    display: block;
    margin: auto;
    color: white;
    margin-left: 2em;
    margin-bottom: 1em;
    background: rgb(255, 123, 0);
    background: radial-gradient(circle, rgba(255, 123, 0, 1) 0%, rgba(255, 123, 0, 1) 35%, rgba(255, 123, 0, 1) 30%, white 233%);
  }


  .a-cv:hover {
    color: white
  }

  .linkgit {
    background: var(--backgroundColor1);
    opacity: .9;
    display: flex;
    justify-content: space-evenly;
    padding: 1em;
    font-size: 1.5em;
    width: 100vw;
  }

  .linkgit-item {
    color: var(--appbgColour);
    cursor: pointer;
    font-size: 2.8em;
  }

  .work-exp-linkedin {
    font-size: 2.9em;
  }

  .work--icons {
    display: none;
  }


  .fa-whatsapp-square {
    color: #25D366;
    font-size: 3em;
  }


  .share-btn {
    color: var(--backgroundColor1);
    font-size: 3em
  }

  .btn-cookies {
    background-color: var(--backgroundColor1);
    color: white;
    border: none;
    border-radius: 5px;
    padding: .3em .8em;
    font-weight: 600;
    font-size: 1em;

  }

  .text-cookies {
    font-weight: 500;
    color: black;
    font-size: .8em;

  }

  .p-cookies {
    position: relative;
    bottom: .7em
  }

  .modal-content {
    position: relative;
    top: -.1em;
    width: 100vw;
    border-radius: 0;
  }

  .modal-body {
    height: 7.6em;
  }

  .modal-dialog {
    margin: 0;
  }

  .span-techs {
    padding: .2em .5em !important;
  }

  .language-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    cursor: pointer;
    left: 80%;
    top: 5%;
    color: white;
  }

  .language-wrapper--init {
    position: absolute;
    cursor: pointer;
    left: 80%;
    top: 5%;
    color: white;
  }

  .icon-lang {
    position: absolute;
    margin-top: .3em;
    cursor: pointer;
    left: 78%;
    top: 20%;
    color: white !important;
  }

  .icon-lang--init {
    position: absolute;
    margin-top: .3em;
    cursor: pointer;
    left: 0%;
    top: 20%;
    color: white !important;
  }

  .see-live-desktop,
  .see-live-desktop .text-light,
  .see-live-desktop .text-info {
    background: white;
    width: 70%;
    vertical-align: middle;
    height: 1.5em;
    border-radius: 5px;
    margin: auto;
    margin-bottom: 1em;
    text-align: center;
    font-size: 18px;
    color: var(--personalInfoColor) !important;
  }

  .form-fields {
    width: 100% !important;
    margin: .3em 0 !important;
  }

  .tox-tinymce {
    height: 300px !important;
    width: auto;
    margin: auto;
  }

  .contact-h2 {
    color: white;
    text-align: center;
    font-size: 1.6em;
  }

  .contact-info-div,
  .contact-info--a {
    color: white !important;
  }

  .contact-info-div {
    display: grid;
    text-align: center;
    line-height: 2em;
  }

}

/*Mobile Landscape*/

@media only screen and (min-device-width: 320px) and (max-device-height:600px) and (orientation:landscape) {


  main {
    width: 100vw;
    background-color: var(--backgroundColor1);
    overflow-x: hidden
  }

  .navbar {

    border-radius: 0 0 5px;
    background: var(--appbgColour);
    line-height: 2.5em;
    margin-bottom: 2em;
  }

  .navbar-nav {
    height: 80vh;
    width: 90vw;
    border-radius: 10px;
    margin-left: 1em !important;
  }

  .navbar-nav>li {
    margin-left: 1em;
  }

  .navbar-toggler-icon {
    color: var(--backgroundColor1);
    font-weight: 900;
    font-family: 'Font Awesome\ 5 Free';
    vertical-align: middle;
    font-size: 1.5em;
    align-self: center;
  }

  .navbar-toggler {
    background-color: white;
    margin-top: .5em;
    height: 2em;
  }

  .unai-pic {
    height: 12em;
    display: block;
    margin: auto;
    border-radius: 50%;
  }

  .estudios {
    display: none;
  }

  .about-div,
  .latestWorks-div {
    background-color: white;
  }


  .h2--about,
  .h2-About {
    background: var(--appbgColour);
    background: -webkit-linear-gradient(to top, var(--appbgColour) 30%, var(--backgroundColor1) 100%);
    background: -moz-linear-gradient(to top, var(--appbgColour) 30%, var(--backgroundColor1) 100%);
    background: linear-gradient(to top, var(--appbgColour) 30%, var(--backgroundColor1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 1.6em;
    padding-top: 1em;
  }

  .about-hr {
    display: none;
  }

  .about-info--1,
  .education-info,
  .about-info,
  .info-wrapper,
  .project-info,
  .cert-wrapper,
  .work-div,
  .education-div,
  .latestWorks-div,
  .contact-div {
    font-size: 18px;
    padding: 0 1em 1em 1em;
    line-height: 1.5em;
  }

  .about-info--1>p {
    color: var(--personalInfoColor);
    line-height: 2em;
  }

  .portfolio-div {
    background-color: var(--appbgColour);
  }

  .portfolio-div>.h2-About {
    color: white !important;
    -webkit-text-fill-color: white;
  }

  .latest-works-p {
    color: var(--personalInfoColor);

  }

  .project-info>p,
  .cert-wrapper>ul,
  .p-info {
    background-color: white;
    color: var(--personalInfoColor);
    border-radius: 10px;
    padding: 1em;
    margin-bottom: 1em;
    font-weight: 500;
    box-shadow: 1px 3px 1px var(--appbgColour);
  }

  .about-info--1>p:last-child,
  .education-info>p:last-child,
  .about-info>p:last-child,
  .info-wrapper>p:last-child,
  .project-info>p:last-child,
  .cert-wrapper>p:last-child {
    margin-bottom: 1em !important;
  }

  .p-info>.text-light {
    color: var(--personalInfoColor) !important;
    font-size: 1em;
    float: none !important
  }

  .webDesigner-p {
    display: none;
  }

  .logo--tech {
    height: 3em;
    object-fit: contain;
    padding: 1px 15px;
  }

  .heroku,
  .firebase {
    margin-left: .8em;
  }

  .p-description {
    color: white;
    font-size: 1.3em;
    text-align: center;
    font-weight: 700;
  }

  .educ-pic,
  .cert-pic {
    display: none;
  }

  .hr-desktop {
    display: none;
  }

  .softSkills-list {
    width: 78%;
    text-align: center;
    margin: auto;
    line-height: 9.3em;
    margin-top: 0em;
  }

  .portfolio--techs {
    min-height: 30px;
    max-height: 40px;
    margin-bottom: 1em;
    filter: opacity(1) drop-shadow(0 0 0 white);
  }

  .udemy--list {
    list-style: none;
    margin-bottom: 2em;
  }

  .logo-works-desktop {
    height: 150px;
  }

  .h3-heading-enquieries {
    color: var(--backgroundColor1);
    font-weight: 700;
    text-align: left;
    margin-top: 2em;
    margin-left: 1em;
    padding-top: 1em
  }

  .about--mobile,
  .workexp--mobile,
  .portfolio--mobile {
    background-color: white;
    padding: 1em 1.5em 1em 1.5em;
    margin-top: .5em;

  }

  .about--mobile p,
  .workexp--mobile p,
  .portfolio--mobile p {
    color: black;
  }

  .date-works {


    color: white;
    font-weight: 900;
    vertical-align: middle;
    font-size: 1.1em;
  }

  .logo-works {
    height: 150px;
  }

  .p-workplace {
    color: var(--backgroundColor1);
  }

  .work-icons--div {
    display: flex;
    justify-content: center;
  }

  .a-project {
    color: var(--backgroundColor1);
    font-weight: 800;
  }

  .form-fields {
    border: 1px solid var(--backgroundColor1);
  }

  .form-fields::placeholder {
    color: var(--backgroundColor1);
    font-weight: 600;
  }

  .footer {
    background: var(--appbgColour)
  }

  .div-contact {
    display: flex;
    flex-direction: column;
    padding-bottom: 1.2em;
    line-height: 1.7em;
  }

  .p-contactinfo {
    color: white;
    width: 30em;
    margin-left: 2em;
  }

  .p-contactinfo-firm {
    color: white;
    margin-left: 1.8em
  }

  .btn-send {
    background: var(--backgroundColor1);
    color: white;
    font-weight: 700;
    border: none;
    padding: .5em;
    margin-bottom: 1em;
    border-radius: 5px;

  }

  .pic-landscape {
    visibility: hidden !important;
    z-index: -1000;
    position: absolute;
    height: 0;
  }

  .a-cv {
    display: block;
    margin: auto;
    color: white;
    margin-left: 2em;
    margin-bottom: 1em;
    background: rgb(255, 123, 0);
    background: radial-gradient(circle, rgba(255, 123, 0, 1) 0%, rgba(255, 123, 0, 1) 35%, rgba(255, 123, 0, 1) 30%, white 233%);
  }


  .a-cv:hover {
    color: white
  }

  .linkgit {
    background: var(--backgroundColor1);
    opacity: .9;
    display: flex;
    justify-content: space-evenly;
    padding: 1em;
    font-size: 1.5em;
    width: 100vw;
  }

  .linkgit-item {
    color: var(--appbgColour);
    cursor: pointer;
    font-size: 2.8em;
  }

  .work-exp-linkedin {
    font-size: 2.9em;
  }

  .work--icons {
    display: none;
  }


  .fa-whatsapp-square {
    color: #25D366;
    font-size: 3em;
  }


  .share-btn {
    color: var(--backgroundColor1);
    font-size: 3em
  }

  .btn-cookies {
    background-color: var(--backgroundColor1);
    color: white;
    border: none;
    border-radius: 5px;
    padding: .3em .8em;
    font-weight: 600;
    font-size: 1em;

  }

  .text-cookies {
    font-weight: 500;
    color: black;
    font-size: .8em;

  }

  .p-cookies {
    position: relative;
    bottom: .7em
  }

  .modal-content {
    position: relative;
    top: -.1em;
    width: 100vw;
    border-radius: 0;
  }

  .modal-body {
    height: 7.6em;
  }

  .modal-dialog {
    margin: 0;
  }

  .span-techs {
    padding: .2em .5em !important;
  }

  .language-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    cursor: pointer;
    left: 80%;
    top: 5%;
    color: white;
  }

  .language-wrapper--init {
    position: absolute;
    cursor: pointer;
    left: 80%;
    top: 5%;
    color: white;
  }

  .icon-lang {
    position: absolute;
    margin-top: .3em;
    cursor: pointer;
    left: 78%;
    top: 20%;
    color: white !important;
  }

  .icon-lang--init {
    position: absolute;
    margin-top: .3em;
    cursor: pointer;
    left: 0%;
    top: 20%;
    color: white !important;
  }

  .see-live-desktop,
  .see-live-desktop .text-light,
  .see-live-desktop .text-info {
    background: white;
    width: 70%;
    vertical-align: middle;
    height: 1.5em;
    border-radius: 5px;
    margin: auto;
    margin-bottom: 1em;
    text-align: center;
    font-size: 18px;
    color: var(--personalInfoColor) !important;
  }

  .form-fields {
    width: 100% !important;
    margin: .3em 0 !important;
  }

  .tox-tinymce {
    height: 300px !important;
    width: auto;
    margin: auto;
  }

  .contact-h2 {
    color: white;
    text-align: center;
    font-size: 1.6em;
  }

  .contact-info-div,
  .contact-info--a {
    color: white !important;
  }

  .contact-info-div {
    display: grid;
    text-align: center;
    line-height: 2em;
  }

}

/*Regular Tablet Portrait*/
@media only screen and (min-width:768px) and (max-width:1024px) and (orientation:portrait) {


  main {
    width: 100vw;
    background-color: var(--backgroundColor1);
    overflow-x: hidden
  }

  .navbar {

    border-radius: 0 0 5px;
    background: var(--appbgColour);
    line-height: 2.5em;
    margin-bottom: 2em;
  }

  .navbar-nav {
    height: 38vh;
    width: 90vw;
    border-radius: 10px;
    margin-left: 1em !important;
  }

  .navbar-nav>li {
    margin-left: 1em;
  }

  .navbar-toggler-icon {
    color: var(--backgroundColor1);
    font-weight: 900;
    font-family: 'Font Awesome\ 5 Free';
    vertical-align: middle;
    font-size: 1.5em;
    align-self: center;
  }

  .navbar-toggler {
    background-color: white;
    margin-top: .5em;
    height: 2em;
  }

  .unai-pic {
    height: 12em;
    display: block;
    margin: auto;
    border-radius: 50%;
  }

  .estudios {
    display: none;
  }

  .about-div,
  .latestWorks-div {
    background-color: white;
  }


  .h2--about,
  .h2-About {
    background: var(--appbgColour);
    background: -webkit-linear-gradient(to top, var(--appbgColour) 30%, var(--backgroundColor1) 100%);
    background: -moz-linear-gradient(to top, var(--appbgColour) 30%, var(--backgroundColor1) 100%);
    background: linear-gradient(to top, var(--appbgColour) 30%, var(--backgroundColor1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 1.6em;
  }

  .about-hr {
    display: none;
  }

  .about-info--1,
  .education-info,
  .about-info,
  .info-wrapper,
  .project-info,
  .cert-wrapper,
  .work-div,
  .education-div,
  .latestWorks-div,
  .contact-div {
    font-size: 18px;
    padding: 0 1em 1em 1em;
    line-height: 1.5em;
  }

  .about-info--1>p {
    color: var(--personalInfoColor);
    line-height: 2em;
  }

  .portfolio-div {
    background-color: var(--appbgColour);
  }

  .portfolio-div>.h2-About {
    color: white !important;
    -webkit-text-fill-color: white;
  }

  .latest-works-p {
    color: var(--personalInfoColor);

  }

  .project-info>p,
  .cert-wrapper>ul,
  .p-info {
    background-color: white;
    color: var(--personalInfoColor);
    border-radius: 10px;
    padding: 1em;
    margin-bottom: 1em;
    font-weight: 500;
    box-shadow: 1px 3px 1px var(--appbgColour);
  }

  .about-info--1>p:last-child,
  .education-info>p:last-child,
  .about-info>p:last-child,
  .info-wrapper>p:last-child,
  .project-info>p:last-child,
  .cert-wrapper>p:last-child {
    margin-bottom: 1em !important;
  }

  .p-info>.text-light {
    color: var(--personalInfoColor) !important;
    font-size: 1em;
    float: none !important
  }

  .webDesigner-p {
    display: none;
  }

  .logo--tech {
    height: 3em;
    object-fit: contain;
    padding: 1px 15px;
  }

  .heroku,
  .firebase {
    margin-left: .8em;
  }

  .p-description {
    color: white;
    font-size: 1.3em;
    text-align: center;
    font-weight: 700;
  }

  .educ-pic,
  .cert-pic {
    display: none;
  }

  .hr-desktop {
    display: none;
  }

  .softSkills-list {
    width: 59%;
    height: 69vh;
    text-align: center;
    margin: auto;
    line-height: 18.3em;
    margin-top: 0em
  }

  .portfolio--techs {
    min-height: 30px;
    max-height: 40px;
    margin-bottom: 1em;
    filter: opacity(1) drop-shadow(0 0 0 white);
  }

  .udemy--list {
    list-style: none;
    margin-bottom: 2em;
  }

  .logo-works-desktop {
    height: 150px;
  }

  .h3-heading-enquieries {
    color: var(--backgroundColor1);
    font-weight: 700;
    text-align: left;
    margin-top: 2em;
    margin-left: 1em;
    padding-top: 1em
  }

  .about--mobile,
  .workexp--mobile,
  .portfolio--mobile {
    background-color: white;
    padding: 1em 1.5em 1em 1.5em;
    margin-top: .5em;

  }

  .about--mobile p,
  .workexp--mobile p,
  .portfolio--mobile p {
    color: black;
  }

  .date-works {


    color: white;
    font-weight: 900;
    vertical-align: middle;
    font-size: 1.1em;
  }

  .logo-works {
    height: 150px;
  }

  .p-workplace {
    color: var(--backgroundColor1);
  }

  .work-icons--div {
    display: flex;
    justify-content: center;
  }

  .a-project {
    color: var(--backgroundColor1);
    font-weight: 800;
  }

  .form-fields {
    border: 1px solid var(--backgroundColor1);
  }

  .form-fields::placeholder {
    color: var(--backgroundColor1);
    font-weight: 600;
  }

  .footer {
    background: var(--appbgColour)
  }

  .div-contact {
    display: flex;
    flex-direction: column;
    padding-bottom: 1.2em;
    line-height: 1.7em;
  }

  .p-contactinfo {
    color: white;
    width: 30em;
    margin-left: 2em;
  }

  .p-contactinfo-firm {
    color: white;
    margin-left: 1.8em
  }

  .btn-send {
    background: var(--backgroundColor1);
    color: white;
    font-weight: 700;
    border: none;
    padding: .5em;
    margin-bottom: 1em;
    border-radius: 5px;

  }

  .pic-landscape {
    visibility: hidden !important;
    z-index: -1000;
    position: absolute;
    height: 0;
  }

  .a-cv {
    display: block;
    margin: auto;
    color: white;
    margin-left: 2em;
    margin-bottom: 1em;
    background: rgb(255, 123, 0);
    background: radial-gradient(circle, rgba(255, 123, 0, 1) 0%, rgba(255, 123, 0, 1) 35%, rgba(255, 123, 0, 1) 30%, white 233%);
  }


  .a-cv:hover {
    color: white
  }

  .linkgit {
    background: var(--backgroundColor1);
    opacity: .9;
    display: flex;
    justify-content: space-evenly;
    padding: 1em;
    font-size: 1.5em;
    width: 100vw;
  }

  .linkgit-item {
    color: var(--appbgColour);
    cursor: pointer;
    font-size: 2.8em;
  }

  .work-exp-linkedin {
    font-size: 2.9em;
  }

  .work--icons {
    display: none;
  }


  .fa-whatsapp-square {
    color: #25D366;
    font-size: 3em;
  }


  .share-btn {
    color: var(--backgroundColor1);
    font-size: 3em
  }

  .btn-cookies {
    background-color: var(--backgroundColor1);
    color: white;
    border: none;
    border-radius: 5px;
    padding: .3em .8em;
    font-weight: 600;
    font-size: 1em;

  }

  .text-cookies {
    font-weight: 500;
    color: black;
    font-size: .8em;

  }

  .p-cookies {
    position: relative;
    bottom: .7em
  }

  .modal-content {
    position: relative;
    top: -.1em;
    width: 100vw;
    border-radius: 0;
  }

  .modal-body {
    height: 7.6em;
  }

  .modal-dialog {
    margin: 0;
  }

  .span-techs {
    padding: .2em .5em !important;
  }

  .language-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    cursor: pointer;
    left: 80%;
    top: 5%;
    color: white;
  }

  .language-wrapper--init {
    position: absolute;
    cursor: pointer;
    left: 80%;
    top: 5%;
    color: white;
  }

  .icon-lang {
    position: absolute;
    margin-top: .3em;
    cursor: pointer;
    left: 78%;
    top: 20%;
    color: white !important;
  }

  .icon-lang--init {
    position: absolute;
    margin-top: .3em;
    cursor: pointer;
    left: 0%;
    top: 20%;
    color: white !important;
  }

  .see-live-desktop,
  .see-live-desktop .text-light,
  .see-live-desktop .text-info {
    background: white;
    width: 70%;
    vertical-align: middle;
    height: 1.5em;
    border-radius: 5px;
    margin: auto;
    margin-bottom: 1em;
    text-align: center;
    font-size: 18px;
    color: var(--personalInfoColor) !important;
  }

  .form-fields {
    width: 100% !important;
    margin: .3em 0 !important;
  }

  .tox-tinymce {
    height: 300px !important;
    width: auto;
    margin: auto;
  }

  .contact-h2 {
    color: white;
    text-align: center;
    font-size: 1.6em;
  }

  .contact-info-div,
  .contact-info--a {
    color: white !important;
  }

  .contact-info-div {
    display: grid;
    text-align: center;
    line-height: 2em;
  }

}

/* Regular Tablet landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-device-height:768px) and (orientation: landscape) {


  main {
    width: 100vw;
    background-color: var(--backgroundColor1);
    overflow-x: hidden
  }

  .navbar {

    border-radius: 0 0 5px;
    background: var(--appbgColour);
    line-height: 2.5em;
    margin-bottom: 2em;
  }

  .navbar-nav {
    width: 90vw;
    border-radius: 10px;
    margin-left: 1em !important;
  }

  .navbar-nav>li {
    margin-left: 1em;
  }

  .navbar-toggler-icon {
    color: var(--backgroundColor1);
    font-weight: 900;
    font-family: 'Font Awesome\ 5 Free';
    vertical-align: middle;
    font-size: 1.5em;
    align-self: center;
  }

  .navbar-toggler {
    background-color: white;
    margin-top: .5em;
    height: 2em;
  }

  .unai-pic {
    height: 12em !important;
    display: block !important;
    margin: auto !important;
    border-radius: 50%;
    position: static !important;
    opacity: 1 !important;
  }

  .estudios {
    display: none;
  }

  .about-div,
  .latestWorks-div {
    background-color: white;
  }


  .h2--about,
  .h2-About {
    background: var(--appbgColour);
    background: -webkit-linear-gradient(to top, var(--appbgColour) 30%, var(--backgroundColor1) 100%);
    background: -moz-linear-gradient(to top, var(--appbgColour) 30%, var(--backgroundColor1) 100%);
    background: linear-gradient(to top, var(--appbgColour) 30%, var(--backgroundColor1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 1.6em;
    padding-top: .5em;
  }

  .about-hr {
    display: none;
  }

  .about-info--1,
  .education-info,
  .about-info,
  .info-wrapper,
  .project-info,
  .cert-wrapper,
  .work-div,
  .education-div,
  .latestWorks-div,
  .contact-div {
    font-size: 18px;
    padding: 0 1em 1em 1em;
    line-height: 1.5em;
  }

  .about-info--1>p {
    color: var(--personalInfoColor);
    line-height: 2em;
  }

  .portfolio-div {
    background-color: var(--appbgColour);
  }

  .portfolio-div>.h2-About {
    color: white !important;
    -webkit-text-fill-color: white;
  }

  .latest-works-p {
    color: var(--personalInfoColor);

  }

  .project-info>p,
  .cert-wrapper>ul,
  .p-info {
    background-color: white;
    color: var(--personalInfoColor);
    border-radius: 10px;
    padding: 1em;
    margin-bottom: 1em;
    font-weight: 500;
    box-shadow: 1px 3px 1px var(--appbgColour);
  }

  .about-info--1>p:last-child,
  .education-info>p:last-child,
  .about-info>p:last-child,
  .info-wrapper>p:last-child,
  .project-info>p:last-child,
  .cert-wrapper>p:last-child {
    margin-bottom: 1em !important;
  }

  .p-info>.text-light {
    color: var(--personalInfoColor) !important;
    font-size: 1em;
    float: none !important
  }

  .webDesigner-p {
    display: none;
  }

  .logo--tech {
    height: 3em;
    object-fit: contain;
    padding: 1px 15px;
  }

  .heroku,
  .firebase {
    margin-left: .8em;
  }

  .p-description {
    color: white;
    font-size: 1.3em;
    text-align: center;
    font-weight: 700;
  }

  .educ-pic,
  .cert-pic {
    display: none;
  }

  .hr-desktop {
    display: none;
  }

  .softSkills-list {
    width: 59%;
    height: 69vh;
    text-align: center;
    margin: auto;
    line-height: 18.3em;
    margin-top: 0em
  }

  .portfolio--techs {
    min-height: 30px;
    max-height: 40px;
    margin-bottom: 1em;
    filter: opacity(1) drop-shadow(0 0 0 white);
  }

  .udemy--list {
    list-style: none;
    margin-bottom: 2em;
  }

  .logo-works-desktop {
    height: 150px;
  }

  .h3-heading-enquieries {
    color: var(--backgroundColor1);
    font-weight: 700;
    text-align: left;
    margin-top: 2em;
    margin-left: 1em;
    padding-top: 1em
  }

  .about--mobile,
  .workexp--mobile,
  .portfolio--mobile {
    background-color: white;
    padding: 1em 1.5em 1em 1.5em;
    margin-top: .5em;

  }

  .about--mobile p,
  .workexp--mobile p,
  .portfolio--mobile p {
    color: black;
  }

  .date-works {


    color: white;
    font-weight: 900;
    vertical-align: middle;
    font-size: 1.1em;
  }

  .logo-works {
    height: 150px;
  }

  .p-workplace {
    color: var(--backgroundColor1);
  }

  .work-icons--div {
    display: flex;
    justify-content: center;
  }

  .a-project {
    color: var(--backgroundColor1);
    font-weight: 800;
  }

  .form-fields {
    border: 1px solid var(--backgroundColor1);
  }

  .form-fields::placeholder {
    color: var(--backgroundColor1);
    font-weight: 600;
  }

  .footer {
    background: var(--appbgColour)
  }

  .div-contact {
    display: flex;
    flex-direction: column;
    padding-bottom: 1.2em;
    line-height: 1.7em;
  }

  .p-contactinfo {
    color: white;
    width: 30em;
    margin-left: 2em;
  }

  .p-contactinfo-firm {
    color: white;
    margin-left: 1.8em
  }

  .btn-send {
    background: var(--backgroundColor1);
    color: white;
    font-weight: 700;
    border: none;
    padding: .5em;
    margin-bottom: 1em;
    border-radius: 5px;

  }

  .pic-landscape {
    visibility: hidden !important;
    z-index: -1000;
    position: absolute;
    height: 0;
  }

  .a-cv {
    display: block;
    margin: auto;
    color: white;
    margin-left: 2em;
    margin-bottom: 1em;
    background: rgb(255, 123, 0);
    background: radial-gradient(circle, rgba(255, 123, 0, 1) 0%, rgba(255, 123, 0, 1) 35%, rgba(255, 123, 0, 1) 30%, white 233%);
  }


  .a-cv:hover {
    color: white
  }

  .linkgit {
    background: var(--backgroundColor1);
    opacity: .9;
    display: flex;
    justify-content: space-evenly;
    padding: 1em;
    font-size: 1.5em;
    width: 100vw;
  }

  .linkgit-item {
    color: var(--appbgColour);
    cursor: pointer;
    font-size: 2.8em;
  }

  .work-exp-linkedin {
    font-size: 2.9em;
  }

  .work--icons {
    display: none;
  }


  .fa-whatsapp-square {
    color: #25D366;
    font-size: 3em;
  }


  .share-btn {
    color: var(--backgroundColor1);
    font-size: 3em
  }

  .btn-cookies {
    background-color: var(--backgroundColor1);
    color: white;
    border: none;
    border-radius: 5px;
    padding: .3em .8em;
    font-weight: 600;
    font-size: 1em;

  }

  .text-cookies {
    font-weight: 500;
    color: black;
    font-size: .8em;

  }

  .p-cookies {
    position: relative;
    bottom: .7em
  }

  .modal-content {
    position: relative;
    top: -.1em;
    width: 100vw;
    border-radius: 0;
  }

  .modal-body {
    height: 7.6em;
  }

  .modal-dialog {
    margin: 0;
  }

  .span-techs {
    padding: .2em .5em !important;
  }

  .language-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    cursor: pointer;
    left: 80%;
    top: 5%;
    color: white;
  }

  .language-wrapper--init {
    position: absolute;
    cursor: pointer;
    left: 80%;
    top: 5%;
    color: white;
  }

  .icon-lang {
    position: absolute;
    margin-top: .3em;
    cursor: pointer;
    left: 78%;
    top: 20%;
    color: white !important;
  }

  .icon-lang--init {
    position: absolute;
    margin-top: .3em;
    cursor: pointer;
    left: 0%;
    top: 20%;
    color: white !important;
  }

  .see-live-desktop,
  .see-live-desktop .text-light,
  .see-live-desktop .text-info {
    background: white;
    width: 70%;
    vertical-align: middle;
    height: 1.5em;
    border-radius: 5px;
    margin: auto;
    margin-bottom: 1em;
    text-align: center;
    font-size: 18px;
    color: var(--personalInfoColor) !important;
  }

  .form-fields {
    width: 100% !important;
    margin: .3em 0 !important;
  }

  .tox-tinymce {
    height: 300px !important;
    width: auto;
    margin: auto;
  }

  .contact-h2 {
    color: white;
    text-align: center;
    font-size: 1.6em;
  }

  .contact-info-div,
  .contact-info--a {
    color: white !important;
  }

  .contact-info-div {
    display: grid;
    text-align: center;
    line-height: 2em;
  }

}

/*Ipad Pro portrait*/
@media only screen and (max-device-width:1024px) and (min-device-width:1024px) {  


  main {
    width: 100vw;
    background-color: var(--backgroundColor1);
    overflow-x: hidden
  }

  .navbar {

    border-radius: 0 0 5px;
    background: var(--appbgColour);
    line-height: 2.5em;
    height: 5em;
    margin-bottom: 2em;
  }

  .navbar-nav {
    height: auto !important;
    background: var(--appbgColour);
    width: 90vw;
    border-radius: 10px;
    margin-left: 1em !important;
  }

  .navbar-nav>li {
    margin-left: 1em;
  }

  .navbar-toggler-icon {
    color: var(--backgroundColor1);
    font-weight: 900;
    font-family: 'Font Awesome\ 5 Free';
    vertical-align: middle;
    font-size: 1.5em;
    align-self: center;
  }

  .navbar-toggler {
    background-color: white;
    margin-top: .5em;
    height: 2em;
  }

  .unai-pic {
    height: 12em !important;
    display: block !important;
    margin: auto !important;
    border-radius: 50%;
    position: static !important;
    opacity: 1 !important;
  }

  .estudios {
    display: none;
  }

  .about-div,
  .latestWorks-div {
    background-color: white;
  }


  .h2--about,
  .h2-About {
    background: var(--appbgColour);
    background: -webkit-linear-gradient(to top, var(--appbgColour) 30%, var(--backgroundColor1) 100%);
    background: -moz-linear-gradient(to top, var(--appbgColour) 30%, var(--backgroundColor1) 100%);
    background: linear-gradient(to top, var(--appbgColour) 30%, var(--backgroundColor1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 1.8em;
    padding-top: .5em;
  }

  .about-hr {
    display: none;
  }

  .about-info--1,
  .education-info,
  .about-info,
  .info-wrapper,
  .project-info,
  .cert-wrapper,
  .work-div,
  .education-div,
  .latestWorks-div,
  .contact-div {
    font-size: 25px;
    padding: 0 1em 1em 1em;
    line-height: 1.5em;
  }

  .about-info--1>p {
    color: var(--personalInfoColor);
    line-height: 2em;
  }

  .portfolio-div {
    background-color: var(--appbgColour);
  }

  .portfolio-div>.h2-About {
    color: white !important;
    -webkit-text-fill-color: white;
  }

  .latest-works-p {
    color: var(--personalInfoColor);

  }

  .project-info>p,
  .cert-wrapper>ul,
  .p-info {
    background-color: white;
    color: var(--personalInfoColor);
    border-radius: 10px;
    padding: 1em;
    margin-bottom: 1em;
    font-weight: 500;
    box-shadow: 1px 3px 1px var(--appbgColour);
  }

  .about-info--1>p:last-child,
  .education-info>p:last-child,
  .about-info>p:last-child,
  .info-wrapper>p:last-child,
  .project-info>p:last-child,
  .cert-wrapper>p:last-child {
    margin-bottom: 1em !important;
  }

  .p-info>.text-light {
    color: var(--personalInfoColor) !important;
    font-size: 1.3em;
    float: none !important
  }

  .webDesigner-p {
    display: none;
  }

  .logo--tech {
    height: 5em;
    object-fit: contain;
    padding: 1px 15px;
  }

  .heroku,
  .firebase {
    margin-left: .8em;
  }

  .p-description {
    color: white;
    font-size: 1.5em !important;
    text-align: center;
    font-weight: 700;
  }

  .educ-pic,
  .cert-pic {
    display: none;
  }

  .hr-desktop {
    display: none;
  }

  .softSkills-list {
    width: 48%;
    height: 77vh;
    text-align: center;
    margin: auto;
    line-height: 18.3em;
    margin-top: 0em
  }

  .portfolio--techs {
    min-height: 30px;
    max-height: 40px;
    margin-bottom: 1em;
    filter: opacity(1) drop-shadow(0 0 0 white);
  }

  .udemy--list {
    list-style: none;
    margin-bottom: 2em;
  }

  .logo-works-desktop {
    height: 150px;
  }

  .h3-heading-enquieries {
    color: var(--backgroundColor1);
    font-weight: 700;
    text-align: left;
    margin-top: 2em;
    margin-left: 1em;
    padding-top: 1em
  }

  .about--mobile,
  .workexp--mobile,
  .portfolio--mobile {
    background-color: white;
    padding: 1em 1.5em 1em 1.5em;
    margin-top: .5em;

  }

  .about--mobile p,
  .workexp--mobile p,
  .portfolio--mobile p {
    color: black;
  }

  .date-works {


    color: white;
    font-weight: 900;
    vertical-align: middle;
    font-size: 1.2em;
  }

  .logo-works {
    height: 150px;
  }

  .p-workplace {
    color: var(--backgroundColor1);
  }

  .work-icons--div {
    display: flex;
    justify-content: center;
  }

  .a-project {
    color: var(--backgroundColor1);
    font-weight: 800;
  }

  .form-fields {
    border: 1px solid var(--backgroundColor1);
  }

  .form-fields::placeholder {
    color: var(--backgroundColor1);
    font-weight: 600;
  }

  .footer {
    background: var(--appbgColour)
  }

  .div-contact {
    display: flex;
    flex-direction: column;
    padding-bottom: 1.2em;
    line-height: 1.7em;
  }

  .p-contactinfo {
    color: white;
    width: 30em;
    margin-left: 2em;
  }

  .p-contactinfo-firm {
    color: white;
    margin-left: 1.8em
  }

  .btn-send {
    background: var(--backgroundColor1);
    color: white;
    font-weight: 700;
    border: none;
    padding: .5em;
    margin-bottom: 1em;
    border-radius: 5px;

  }

  .pic-landscape {
    visibility: hidden !important;
    z-index: -1000;
    position: absolute;
    height: 0;
  }

  .a-cv {
    display: block;
    margin: auto;
    color: white;
    margin-left: 2em;
    margin-bottom: 1em;
    background: rgb(255, 123, 0);
    background: radial-gradient(circle, rgba(255, 123, 0, 1) 0%, rgba(255, 123, 0, 1) 35%, rgba(255, 123, 0, 1) 30%, white 233%);
  }


  .a-cv:hover {
    color: white
  }

  .linkgit {
    background: var(--backgroundColor1);
    opacity: .9;
    display: flex;
    justify-content: space-evenly;
    padding: 1em;
    font-size: 1.5em;
    width: 100vw;
  }

  .linkgit-item {
    color: var(--appbgColour);
    cursor: pointer;
    font-size: 2.8em;
  }

  .work-exp-linkedin {
    font-size: 2.9em;
  }

  .work--icons {
    display: none;
  }


  .fa-whatsapp-square {
    color: #25D366;
    font-size: 3em;
  }


  .share-btn {
    color: var(--backgroundColor1);
    font-size: 3em
  }

  .btn-cookies {
    background-color: var(--backgroundColor1);
    color: white;
    border: none;
    border-radius: 5px;
    padding: .3em .8em;
    font-weight: 600;
    font-size: 1em;

  }

  .text-cookies {
    font-weight: 500;
    color: black;
    font-size: .8em;

  }

  .p-cookies {
    position: relative;
    bottom: .7em
  }

  .modal-content {
    position: relative;
    top: -.1em;
    width: 100vw;
    border-radius: 0;
  }

  .modal-body {
    height: 7.6em;
  }

  .modal-dialog {
    margin: 0;
  }

  .span-techs {
    padding: .2em .5em !important;
  }

  .language-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    cursor: pointer;
    left: 80%;
    top: 5%;
    color: white;
  }

  .language-wrapper--init {
    position: absolute;
    cursor: pointer;
    left: 80%;
    top: 5%;
    color: white;
  }

  .icon-lang {
    position: absolute;
    margin-top: .3em;
    cursor: pointer;
    left: 78%;
    top: 20%;
    color: white !important;
  }

  .icon-lang--init {
    position: absolute;
    margin-top: .3em;
    cursor: pointer;
    left: 0%;
    top: 20%;
    color: white !important;
  }

  .see-live-desktop,
  .see-live-desktop .text-light,
  .see-live-desktop .text-info {
    background: white;
    width: 70%;
    vertical-align: middle;
    height: 1.5em;
    border-radius: 5px;
    margin: auto;
    margin-bottom: 1em;
    text-align: center;
    font-size: 18px;
    color: var(--personalInfoColor) !important;
  }

  .form-fields {
    width: 100% !important;
    margin: .3em 0 !important;
    font-size: 1em;
  }

  .form-fields::placeholder {
    font-weight: 400;
  }

  .tox-tinymce {
    height: 300px !important;
    width: auto;
    margin: auto;
  }

  .btn-send-form {
    height: 3em;
    font-size: 1.1em;
    width: 9em;
    display: block;
    margin: auto;
  }

  .contact-h2 {
    color: white;
    text-align: center;
    font-size: 3em;
  }

  .contact-info-div,
  .contact-info--a {
    color: white !important;
  }

  .contact-info-div {
    display: grid;
    text-align: center;
    line-height: 2em;
    font-size: 1.5em;
  }

}

/*Ipad Pro landscape*/
@media only screen and (max-device-width:1366px) and (min-device-width:1366px) {

  .navbar {
    background-color: var(--navbarBlack);
  }

  .unai-pic {
    height: 250px;
    position: relative;
    left: 8em;
    top: 3em;
    border-radius: 50%;
  }

  .PictureDescrip-div {
    height: 93.2vh;
    background-color: var(--backgroundColor1);
    border-bottom-right-radius: 600px;
  }

  .p-description {
    width: 5em;
    font-size: 3em;
    position: relative;
    left: 11em;
    bottom: 3.5em;
    color: black;
    font-weight: 700;
  }


  .p-info,  
  .about-info--1 > p,
  .latest-works-p{
    font-size: 1.6em;
  }

  .hr-desktop {
    border: .2px solid black;
    width: 68vw;
    position: relative;
    bottom: 8em;
    left: 10.5em;
  }

  .webDesigner-p {
    color: black;
    position: relative;
    left: 27.7em !important;
    font-weight: 600;
    width: 7em;
  }

  .softSkills-list {
    display: inline;
    position: relative;
    left: 20em !important;
    list-style-type: none;
    bottom: 7em;
    font-weight: 400;
    color: black;
  }

  .about-div {
    background-color: black;
    height: 58vh !important;
    display: flex;
    justify-content: space-between;
  }


  .about-info--1 {
    width: 50%;
    padding-right: 2em;
    position: relative;
    float: right;
    color: var(--backgroundColor1);
  }

  .about-info {
    width: 90%;
    padding-right: 2em;
    position: relative;
    float: right;
    color: var(--backgroundColor1);
  }

  .about-hr {
    border: .2px solid var(--backgroundColor1);
    width: 40em;
    position: relative;
    right: 6em;
  }

  .education-div {
    display: flex;
    justify-content: space-between;
  }

  .educ-pic,
  .cert-pic {
    width: 480px;
    min-height: 277px;
    max-height: 300px;
    object-fit: cover;
    margin-right: 5em;
  }


  .info-wrapper {
    margin-left: 10em !important;
    width: 32em;
    color: var(--backgroundColor1);
  }

  .cert-wrapper {
    margin-left: 26em;
    width: 100vw;
    color: var(--backgroundColor1);
  }

  .work-div {
    background-color: black;
    height: 53em
  }

  .logo-works-desktop {
    height: 25em;
  }

  .portfolio-div {
    background-color: black;

  }

  .project-info {
    margin-left: 14em;
    width: 32em;
    color: var(--backgroundColor1);
  }

  .see-live-desktop {
    width: 54em;
    position: relative;
    left: 40% !important;
    color: var(--backgroundColor1);
    margin-top: 1em;
    font-size: 1.8em;
  }


  .btn-send-form {
    background: var(--backgroundColor1);
    border: none;
    border-radius: 5px;
    padding: .5em 1em
  }

  .footer {
    background: var(--backgroundColor1);
    margin-top: 2em;
    padding-bottom: 2em;
  }

  .contact-h2 {
    font-weight: 700;
    margin-left: 7em;
    padding-top: 1em;
    color: black
  }

  .contact-info-div {
    display: flex;
    flex-direction: column;
    margin-left: 9em !important;
    margin-top: 2em;
    line-height: 2.5em;
    color: black;
    font-size: 1.4em;
  }

  .span-love {
    margin-left: 14em;
    color: black
  }

  .unai-ad {
    margin-top: 3.1em;
    margin-left: 12em !important;
    color: black
  }

  .share {
    cursor: pointer;
  }

  .fa-whatsapp-square {
    color: #25D366;
    font-size: 3em;
  }

  .share-btn {
    color: var(--backgroundColor1);
    font-size: 3em;
    position: relative;
    right: .18em;
  }

  .share-text {
    position: relative;
    bottom: 1em;
    font-weight: 800;
  }

  .btn-cookies {
    background-color: var(--backgroundColor1);
    border: none;
    border-radius: 5px;
    padding: .3em .8em;
    font-weight: 600;
    font-size: 1em;
    display: inline !important;
    margin-left: .3em !important
  }

  .text-cookies {
    font-weight: 500;
    color: black;
    font-size: .8em;
    display: flex;
    justify-content: center;
  }

  .p-cookies {
    position: relative;
    bottom: .6em
  }

  .modal-content {
    position: relative;
    top: -.1em;
    width: 100vw;
    border-radius: 0;
  }

  .modal-body {
    height: 3.6em;
  }

  .modal-dialog {
    margin: 0;
  }

  .linkgit-item {
    font-size: 2em;
    color: white;
  }

  .work-exp-linkedin {
    font-size: 2em;
  }

  .rotate-center {
    -webkit-animation: rotate-center 1s linear infinite both;
    animation: rotate-center 1s linear infinite both;
  }


  @-webkit-keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }

    100% {
      -webkit-transform: rotate(320deg);
      transform: rotate(360deg);
    }
  }

  @keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .tox-tinymce {
    margin-top: 2em;
  }


  .fr-element {
    border-top: 0px;
    border: 1px solid #17a2b8 !important;
    border-radius: 0 0 5px 5px;
  }

  .tox .tox-edit-area__iframe {
    border: 1px solid #17a2b8 !important;
    border-radius: 5px
  }

  .a-cv {
    height: 2em;
    width: 12em;
    font-size: 1.2em !important;
    vertical-align: middle;
  }

  .estudios {
    height: 28em;
    border-radius: 5px;
    filter: brightness(0.9);
  }

  .logo--tech {
    height: 4em;
    object-fit: contain;
    padding-left: 1em
  }

  .fr-placeholder::before {
    content: 'Your message goes here...' !important;
    visibility: visible !important;
    color: grey;
    font-family: 'Epilogue', sans-serif;
    font-size: 1.1em;
    font-weight: 600;
  }

  .fr-placeholder {
    visibility: hidden !important;
  }

  .portfolio--techs {
    min-height: 45px;
    max-height: 50px;
    margin-bottom: 1em;
    filter: opacity(1) drop-shadow(0 0 0 white);
  }

  .language-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    cursor: pointer;
  }

  .icon-lang {
    position: absolute;
    margin-left: 4em;
    margin-top: .3em;
    cursor: pointer;
    color: white !important;
  }

  .icon-lang--init {
    position: absolute;
    margin-left: .4em;
    margin-top: .3em;
    cursor: pointer;
    color: white !important;
  }


  .udemy--list {
    position: relative;
    right: 2em;
    list-style: none;
    margin-bottom: 2em;
  }

  .work-div>h2 {
    display: inline-block;
  }

  .education-info {
    margin-left: 14em;
    color: var(--backgroundColor1);
  }

  .workexp-hr {
    border: .2px solid var(--backgroundColor1);
    width: 40em;
    position: relative;
    right: 20.3em;
    bottom: 1em;

  }

  .h2-About {
    color: var(--backgroundColor1);
    font-size: 1.6em;
    margin-left: 8.6em;
    padding-top: .5em;
  }

  .h2--about {
    color: var(--backgroundColor1);
    font-size: 1.6em !important;
    padding-top: .5em;
  }

  .work-icons--div {
    display: inline-block;
  }
}

/*DeskTop*/
@media(min-width:1025px) {

  .navbar {
    background-color: var(--navbarBlack);
  }

  .unai-pic {
    height: 250px;
    position: relative;
    left: 8em;
    top: 3em;
    border-radius: 50%;
  }

  .PictureDescrip-div {
    height: 93.2vh;
    background-color: var(--backgroundColor1);
    border-bottom-right-radius: 600px;
  }

  .p-description {
    width: 5em;
    font-size: 2.5em;
    position: relative;
    left: 11em;
    bottom: 3.5em;
    color: black;
    font-weight: 700;
  }

  .hr-desktop {
    border: .2px solid black;
    width: 68vw;
    position: relative;
    bottom: 8em;
    left: 10.5em;
  }

  .webDesigner-p {
    color: black;
    color: black;
    position: relative;
    left: 27.7em;
    bottom: 7.5em;
    font-weight: 600;
  }

  .softSkills-list {
    display: inline;
    position: relative;
    left: 26.2em;
    list-style-type: none;
    bottom: 7em;
    font-weight: 400;
    color: black;
  }

  .about-div {
    background-color: black;
    height: 55vh;
    display: flex;
    justify-content: space-between;
  }


  .about-info--1 {
    width: 50%;
    padding-right: 2em;
    position: relative;
    float: right;
    color: var(--backgroundColor1);
  }

  .about-info {
    /* width: 50%; */
    padding-right: 2em;
    position: relative;
    float: right;
    color: var(--backgroundColor1);
  }

  .about-hr {
    border: .2px solid var(--backgroundColor1);
    width: 40em;
    position: relative;
    right: 6em;
  }

  .education-div {
    display: flex;
    justify-content: space-between;
  }

  .educ-pic,
  .cert-pic {
    width: 480px;
    min-height: 277px;
    max-height: 300px;
    object-fit: cover;
    margin-right: 5em;
  }


  .info-wrapper {
    margin-left: 14em;
    width: 32em;
    color: var(--backgroundColor1);
  }

  .cert-wrapper {
    margin-left: 26em;
    width: 100vw;
    color: var(--backgroundColor1);
  }

  .work-div {
    background-color: black;
    height: 53em
  }

  .logo-works-desktop {
    height: 25em;
  }

  .portfolio-div {
    background-color: black;

  }

  .project-info {
    margin-left: 14em;
    width: 32em;
    color: var(--backgroundColor1);
  }

  .see-live-desktop {
    width: 54em;
    position: relative;
    left: 33em;
    color: var(--backgroundColor1);
    margin-top: 1em;
  }


  .btn-send-form {
    background: var(--backgroundColor1);
    border: none;
    border-radius: 5px;
    padding: .5em 1em
  }

  .footer {
    background: var(--backgroundColor1);
    margin-top: 2em;
    padding-bottom: 2em;
  }

  .contact-h2 {
    font-weight: 700;
    margin-left: 7em;
    padding-top: 1em;
    color: black
  }

  .contact-info-div {
    display: flex;
    flex-direction: column;
    margin-left: 12.3em;
    margin-top: 2em;
    line-height: 2.5em;
    color: black
  }

  .span-love {
    margin-left: 14em;
    color: black
  }

  .unai-ad {
    margin-top: 3em;
    margin-left: 1em;
    color: black
  }

  .share {
    cursor: pointer;
  }

  .fa-whatsapp-square {
    color: #25D366;
    font-size: 3em;
  }

  .share-btn {
    color: var(--backgroundColor1);
    font-size: 3em;
    position: relative;
    right: .18em;
  }

  .share-text {
    position: relative;
    bottom: 1em;
    font-weight: 800;
  }

  .btn-cookies {
    background-color: var(--backgroundColor1);
    border: none;
    border-radius: 5px;
    padding: .3em .8em;
    font-weight: 600;
    font-size: 1em;
    display: inline !important;
    margin-left: .3em !important
  }

  .text-cookies {
    font-weight: 500;
    color: black;
    font-size: .8em;
    display: flex;
    justify-content: center;
  }

  .p-cookies {
    position: relative;
    bottom: .6em
  }

  .modal-content {
    position: relative;
    top: -.1em;
    width: 100vw;
    border-radius: 0;
  }

  .modal-body {
    height: 3.6em;
  }

  .modal-dialog {
    margin: 0;
  }

  .linkgit-item {
    font-size: 2em;
    color: white;
  }

  .work-exp-linkedin {
    font-size: 2em;
  }

  .rotate-center {
    -webkit-animation: rotate-center 1s linear infinite both;
    animation: rotate-center 1s linear infinite both;
  }


  @-webkit-keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }

    100% {
      -webkit-transform: rotate(320deg);
      transform: rotate(360deg);
    }
  }

  @keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .tox-tinymce {
    margin-top: 2em;
  }


  .fr-element {
    border-top: 0px;
    border: 1px solid #17a2b8 !important;
    border-radius: 0 0 5px 5px;
  }

  .tox .tox-edit-area__iframe {
    border: 1px solid #17a2b8 !important;
    border-radius: 5px
  }

  .a-cv {
    height: 2em;
    width: 12em;
    font-size: 1.2em !important;
    vertical-align: middle;
  }

  .estudios {
    height: 28em;
    border-radius: 5px;
    filter: brightness(0.9);
  }

  .logo--tech {
    height: 4em;
    object-fit: contain;
    padding-left: 1em
  }

  .fr-placeholder::before {
    content: 'Your message goes here...' !important;
    visibility: visible !important;
    color: grey;
    font-family: 'Epilogue', sans-serif;
    font-size: 1.1em;
    font-weight: 600;
  }

  .fr-placeholder {
    visibility: hidden !important;
  }

  .portfolio--techs {
    min-height: 45px;
    max-height: 50px;
    margin-bottom: 1em;
    filter: opacity(1) drop-shadow(0 0 0 white);
  }

  .language-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    cursor: pointer;
  }

  .icon-lang {
    position: absolute;
    margin-left: 4em;
    margin-top: .3em;
    cursor: pointer;
    color: white !important;
  }

  .icon-lang--init {
    position: absolute;
    margin-left: .4em;
    margin-top: .3em;
    cursor: pointer;
    color: white !important;
  }


  .udemy--list {
    position: relative;
    right: 2em;
    list-style: none;
    margin-bottom: 2em;
  }

  .work-div>h2 {
    display: inline-block;
  }

  .education-info {
    margin-left: 14em;
    color: var(--backgroundColor1);
  }

  .workexp-hr {
    border: .2px solid var(--backgroundColor1);
    width: 40em;
    position: relative;
    right: 20.3em;
    bottom: 1em;

  }

  .h2-About {
    color: var(--backgroundColor1);
    font-size: 1.6em;
    margin-left: 8.6em;
    padding-top: .5em;
  }

  .h2--about {
    color: var(--backgroundColor1);
    font-size: 1.6em !important;
    padding-top: .5em;
  }

  .work-icons--div {
    display: inline-block;
  }
}